<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                          placeholder="Tìm tên hoặc SĐT học viên" autocomplete="false"></el-input>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker
                    class="w-100"
                    v-model="form.startDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="pickStart"
                    :picker-options="pickerStartOptions"
                    placeholder="Từ ngày">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker
                    class="w-100"
                    v-model="form.endDate"
                    type="date"
                    ref="picker"
                    :onPick="pickEnd"
                    format="yyyy-MM-dd"
                    @change="pickEnd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerEndOptions"
                    :disabled-date="disabledEndDate"
                    placeholder="Đến ngày">
                </el-date-picker>
              </el-col>
              <el-col v-if="isGDKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.branch_id" filterable class="w-100 mt-3"
                           placeholder="Chọn văn phòng để xem" @change="handleChangeBranch">
                  <el-option
                      v-for="item in branches"
                      :key="item.id"
                      :label="item.name_alias ? item.name_alias : item.name"
                      placeholder="Chọn cơ sở"
                      :value="item.id">
                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col v-if="isGDKV" :xs="24" :sm="12" :md="8" :lg="5" :xl="3">
                <tree-select class="w-100 mt-3" @input="chooseSale"
                             :show-count="true"
                             search-nested v-model="form.sale"
                             placeholder="Chọn nhân sự muốn xem..."
                             :options="options"
                             :normalizer=normalizer
                >
                  <!--eslint-disable-->
                  <label slot="option-label"
                         slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                         :class="labelClassName">
                    <img :src="getImageHierarchy(node.raw.profile.account_type.id)" alt=""> <span> {{
                      node.label
                    }} </span>
                    <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                  </label>
                </tree-select>
              </el-col>
              <el-col v-if="!isGDKV || (isGDKV && form.sale) || (isGDVP && form.sale)" :xs="24" :sm="12" :md="8"
                      :lg="isGDKV ? 4 : 5" :xl="3">
                <el-select v-model="form.view_as" clearable class="w-100 mt-3" placeholder="Chọn tư cách để xem">
                  <el-option
                      v-for="item in watchAs"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8"
                      :lg="isGDKV ? 4 : 5" :xl="3">
                <el-select v-model="form.type" clearable class="w-100 mt-3" placeholder="Phân loại nạp rút">
                  <el-option
                      v-for="item in types"
                      :key="item.id"
                      :label="item.label"
                      :value="item.value">
                    <span style="float: left">{{ item.label }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8"
                      :lg="isGDKV ? 4 : 5" :xl="3">
                      <el-select clearable v-model="form.capacity" filterable class="w-100 mt-3"
                      placeholder="Loại doanh số">
                      <el-option v-for="item in capacityDefine"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
              </el-option>
              </el-select>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading" class="btn btn-primary w-100 mt-3" @click="submit"><i
                    v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
            <div class="mb-5 mt-5">
              <h4>Tổng doanh số: <b class="text-danger">{{ total | formatVND }}</b></h4>
              <!-- <h4>Tổng doanh đội: <b class="text-danger">{{ totalTeam | formatVND }}</b></h4>  -->
              <!-- <h4>Tổng Digital: <b class="text-danger">{{ totalDigital | formatVND }}</b></h4> -->
            </div>

            <el-table
                :data="tableData"
                stripe
                v-loading="loading"
                border
                class="table-hover"
                style="width: 100%">
              <el-table-column label="#" type="index" width="50" :index="indexMethod">
              </el-table-column>
              <el-table-column
                  prop="id"
                  label="Mã hóa đơn"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="Họ và tên"
                  width="160">
              </el-table-column>
              <el-table-column
                  prop="user_id"
                  label="Mã học viên"
                  width="110">
              </el-table-column>
              <el-table-column
                  prop="phone"
                  label="Điện thoại"
                  width="110">
              </el-table-column>
              <el-table-column label="Số tiền nộp" width="150">
                <template v-slot="scope">
                  <i class="el-icon-money"></i>
                  <span style="margin-left: 10px" v-if="scope.row.amount_student">{{ scope.row.amount_student |  formatVND }}</span>
                  <span style="margin-left: 10px" v-else>0</span>
                </template>
              </el-table-column>
              <el-table-column label="Phí trả góp" width="150">
                <template v-slot="scope">
                  <i class="el-icon-money"></i>
                  <span style="margin-left: 10px" v-if="scope.row.is_installment == 1">{{ -(scope.row.amount_student - scope.row.amount_installment) |  formatVND }}</span>
                  <span style="margin-left: 10px" v-else>0đ</span>
                </template>
              </el-table-column>
              <el-table-column label="Hệ số đội" width="90">
                <template v-slot="scope">
                  <span>{{ scope.row.business_coefficient }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Hệ số cá nhân" width="80">
                <template v-slot="scope">
                  <span>{{ scope.row.personal_coefficient }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Hệ số lên cấp" width="90">
                <template v-slot="scope">
                  <span>{{ scope.row.levelup_coefficient }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="checkCoefficient == 2"
                  width="150"
                  :label="checkCoefficient == 2 ? 'Doanh số cá nhân' : ''"
              >
                <template v-slot="scope">
                  <i class="el-icon-money"></i>
                  <!-- <span style="margin-left: 10px" v-if="checkCoefficient == 1 || !checkCoefficient">{{
                          scope.row.amount_installment * scope.row.business_coefficient * (scope.row.type_sale == 1 && scope.row.is_branch_team == 1 && !form.capacity ? 2 : 1)|  formatVND
                    }}</span> -->
                  <span style="margin-left: 10px" v-if="checkCoefficient == 2">{{
                      (scope.row.amount_installment * scope.row.personal_coefficient) * (scope.row.type_sale == 1 && scope.row.is_branch_team == 1 && !form.capacity ? 2 : 1) |  formatVND
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Doanh số lên cấp" width="150">
                <template v-slot="scope">
                  <i class="el-icon-money"></i>
                  <span style="margin-left: 10px" class="text-break">{{ scope.row.amount_installment * scope.row.levelup_coefficient | formatVND }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Chi nhánh quyết toán" width="230">
                <template v-slot="scope">
                  <span style="margin-left: 10px" class="text-break">{{ scope.row.branch_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Tên sale" width="200" v-if="show_field_sale_name">
                <template v-slot="scope">
                  <span style="margin-left: 10px" class="text-break">{{ scope.row.sale_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Văn phòng sale" width="200">
                <template v-slot="scope">
                  <span style="margin-left: 10px" class="text-break">{{ scope.row.branch_sale }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Tư vấn viên" width="200">
                <template v-slot="scope">
                  <span style="margin-left: 10px" class="text-break">{{ scope.row.tvv_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Loại doanh số" width="270">
                <template v-slot="scope">
                  <span style="margin-left: 10px" class="text-break">
                    <el-button v-if="scope.row.type_sale == 1"size="mini" round type="success">Doanh số digital</el-button>
                    <el-button v-if="scope.row.is_branch_team == 1" size="mini" round type="danger">Doanh số key</el-button>
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                  prop="dated_paid"
                  label="Ngày nộp"
                  width="100">
              </el-table-column>
              <el-table-column label="Ngày tạo" width="120">
                <template v-slot="scope">
                  <span style="margin-left: 10px">{{ scope.row.created_at | formatDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Phân loại" width="110">
                <template v-slot="scope">
                  <el-button v-if="scope.row.type==='Nạp tiền'" size="mini" round type="success">Nạp tiền</el-button>
                  <el-button v-if="scope.row.type==='Rút tiền'" size="mini" round type="danger">Rút tiền</el-button>
                  <el-button v-if="scope.row.type==='Hoàn tiền'" size="mini" round type="warning">Hoàn tiền</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="edutalk-paging">
              <div class="block">
                <!--eslint-disable-->
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.current_page"
                    :page-size="paging.per_page"
                    layout="prev, pager, next"
                    :total="paging.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {PAYMENT_LIST} from "@/core/services/store/payment.module";
import {BRANCH_LIST, HIERARCHY} from "@/core/services/store/service.module";
import {GDKV, GDVP, GDVPorGDKV, WATCH_AS_SALE, WATCH_AS_TP} from '@/core/config/accountTypeOption';

import lodash, { isBuffer } from 'lodash-es';
import deepdash from 'deepdash-es';
import {mapGetters} from "vuex";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import moment from "moment-timezone";
import {GET_USER_BY_ID} from "../../../core/services/store/profile.module";

const _ = deepdash(lodash);

export default {
  name: "EdutalkPayment",
  components: {},
  data() {
    return {
      moment: moment,
      pickerStartOptions: {
        disabledDate: this.disabledStartDate
      },
      pickerEndOptions: {
        disabledDate: this.disabledEndDate
      },
      paging: {
        current_page: 1,
        per_page: 10,
        total: 0,
      },
      dialogImageUrl: '',
      loading: false,
      currentPage: 1,
      selectDate: '',
      defaultValue: '',
      isGDKV: false,
      currentDate: new Date(),
      imageHierarchy: {
        '5': 'media/hierarchy/gdvp.png',
        '1': 'media/hierarchy/sl.png',
        '2': 'media/hierarchy/se.png',
        '3': 'media/hierarchy/s.png',
        '4': 'media/hierarchy/si.png'
      },
      total: {
        tong: 0,
        so_luot: 0,
        totalWithBusiness: 0
      },
      totalDigital: 0,
      totalTeam: 0,
      options: [],
      form: {
        name: '',
        startDate: null,
        endDate: null,
        view_as: '',
        branch_id: '',
        sale: null,
        type: ''
      },
      branches: [],
      watchAs: [
        {
          id: 1,
          value: 'Đội kinh doanh',
          label: 'Đội'
        },
        {
          id: 2,
          value: 'Cá nhân',
          label: 'Cá nhân'
        }
      ],
      types: [
        {
          id: 0,
          value: '',
          label: 'Tất cả'
        },
        {
          id: 1,
          value: 1,
          label: 'Nạp tiền'
        },
        {
          id: 2,
          value: 2,
          label: 'Rút tiền'
        },
        {
          id: 3,
          value: 3,
          label: 'Hoàn tiền'
        }
      ],
      capacityDefine:
      [
        {id: 2, name: 'Doanh số Digital'},
        {id: 1, name: 'Doanh số key'}
      ],
      tableData: [],
      show_field_sale_name: false,
      GDVPorGDKV,
      GDKV,
      GDVP,
      WATCH_AS_TP,
      WATCH_AS_SALE,
      startDate: '',
      checkCoefficient: 1,
      filter: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách"},
      {title: "Danh sách đóng tiền", icon: 'far fa-money-bill'}
    ]);
    let date = new Date();
    this.form.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
    this.form.endDate = this.$moment().format('YYYY-MM-DD');
    this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.toDate = new Date();
    this.form.name = this.$route.query.search ?? '';
    this.form.view_as = this.$route.query.view_as &&  this.$route.query.view_as == 'NaN' ? parseInt(this.$route.query.view_as) : '';
    if (this.currentUser.profile.account_type_id === this.GDKV || this.isGDVP) {
      this.watchAs.unshift({
        id: 0,
        value: 'TPhòng/Giám đốc',
        label: 'TP/GĐ'
      });
    }
    if (this.currentUser.profile.account_type_id === this.GDKV) {
      this.isGDKV = true;
      this.getCenter();
    } else {
      // this.form.view_as = null;
      this.querySever();
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    isGDVP() {
      return this.currentUser.profile.account_type_id === this.GDVP
    }
  },
  methods: {
    getImageHierarchy(account_type) {
      return this.imageHierarchy[account_type];
    },
    handleCurrentChange(val) {
      this.querySever({page: val});
    },
    indexMethod(index) {
      return 10 * (this.paging.current_page - 1) + (index + 1);
    },
    submit() {
      this.checkShowFieldName();
      this.querySever();
    },
    checkShowFieldName() {
      if (this.form.view_as == 1) {
        this.show_field_sale_name = true;
      } else {
        this.show_field_sale_name = false;
      }
    },
    handleChangeBranch() {
      if (this.form.branch_id > 0) {
        this.filter = true;
        this.$store.dispatch(HIERARCHY, {branch_id: this.form.branch_id})
            .then((response) => {
              this.form.sale = null;
              this.options = response.data;
            })
      } else {
        this.filter = false;
        this.form.sale = null;
        this.form.view_as = null;
        this.options = [];
      }
    },
    getCenter() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
              this.querySever({branch_id: this.form.branch_id});
              this.handleChangeBranch();
            }
          })

    },
    chooseSale(user_id) {
      if (user_id) {
        this.$store.dispatch(GET_USER_BY_ID, user_id).then((res) => {
          if (res.data.profile.account_type_id !== this.GDVP) {
            this.watchAs = this.WATCH_AS_SALE
          } else {
            this.watchAs = this.WATCH_AS_TP
          }
        })
      }
      if (!this.form.sale) {
        this.form.view_as = null
      }
    },
    normalizer: function (node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    querySever(customProperties) {
      this.loading = true;
      let params = this.mergeParams(customProperties);
      this.$router.push({
        name: 'payment-list',
        query: params
      })
      this.currentPage = 1;
      this.$store.dispatch(PAYMENT_LIST, params)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.tableData = response.data;
              this.total = response.total;
              this.paging = {
                total: response.pagination ? response.pagination.total : '',
                count: response.pagination ? response.pagination.total : '',
                per_page: response.pagination ? response.pagination.per_page : '',
                current_page: response.pagination ? response.pagination.current_page : '',
                last_page: response.pagination ? response.pagination.last_page : '',
              }
              this.totalTeam = response.total.tong ? response.total.tong : 0;
              this.total = parseInt(this.totalDigital) + parseInt(this.totalTeam);
              this.checkCoefficient = this.form.view_as;
            }
            this.loading = false;
          })
    },
    mergeParams(customProperties) {
      let params = {
        // page: this.paging.current_page,
        per_page: this.paging.per_page,
        view_as: this.form.view_as,
        type: this.form.type
      };
      if (this.form.startDate) {
        params = _.merge(params, {startDate: this.form.startDate})
      }
      if (this.form.endDate) {
        params = _.merge(params, {endDate: this.form.endDate})
      }
      if (this.form.name) {
        params = _.merge(params, {search: this.form.name})
      }
      if (this.form.branch_id) {
        params = _.merge(params, {branch_id: this.form.branch_id})
      }
      if (this.form.sale) {
        params = _.merge(params, {sale: this.form.sale})
      }
      if (this.form.view_as) {
        params = _.merge(params, {view_as: this.form.view_as})
      }
      if (this.form.capacity == 1 || this.form.capacity == 2) {
        params = _.merge(params, {capacity: this.form.capacity})
      }
      params = _.merge(params, customProperties);
      return params;
    },
    /* date picker methods */
    pickStart(date) {
      this.fromDate = null;
      if (date) {
        this.fromDate = new Date(date);

      }
      this.checkEndDate(date);
    },
    pickEnd(date) {
      this.toDate = null;
      if (date) {
        this.toDate = new Date(date);
      }
    },
    disabledStartDate(date) {
      if (this.toDate) {
        return this.toDate < date
      }
      return date > new Date();
    },
    disabledEndDate(date) {
      if (this.fromDate) {
        let lastDate = moment(this.fromDate).endOf('month');
        return date > lastDate.toDate() || date <= this.fromDate;
      }
      return true;
    },
    checkEndDate(date){
      let lastDate = moment(date).endOf('month');
      let valueEndDate = moment(this.form.endDate);
      if(!valueEndDate.isBetween(date,lastDate.toDate())) {
        this.form.endDate = moment(date).endOf('month').format('YYYY-MM-DD');
      }
    }
  },

}
</script>

<style>

</style>
